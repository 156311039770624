<template>
  <div :class="animation">
    <b-card>
      <b-form @submit.prevent="tryToSave">
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('support.title')">
              <b-form-input
                v-model="$v.support.title.$model"
                :formatter="formatter"
                :class="{
                  'is-invalid': $v.support.title.$error,
                }"
              />
              <div
                v-if="$v.support.title.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.support.title.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('support.file')">
              <b-form-file
                v-model="support.file"
                accept=".jpg, .jpeg, .png"
                placeholder=""
                :browse-text="$t('browse')"
                @change="uploadFile($event)"
              />
              <b-progress
                v-if="loadImage"
                variant="primary"
                height="3px"
                :value="progress"
                :max="100"
                class="w-100 mt-1"
              />
              <div
                v-if="this.$v.support.file.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.support.file.checkSize">Taille maximale: 5MB</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('support.message')">
              <b-form-textarea
                v-model="$v.support.message.$model"
                :placeholder="$t('support.minMessageLength')"
                :class="{
                  'is-invalid': $v.support.message.$error,
                }"
                rows="8"
                no-resize
              />
              <div
                v-if="$v.support.message.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.support.message.required">{{
                  $t("errors.required")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <div class="text-center">
              <b-spinner
                v-if="process"
                variant="primary"
              />
            </div>
            <div class="mt-2">
              <b-button
                variant="secondary"
                class="float-left"
                :disabled="process"
                @click="resetForm"
              >
                {{ $t("form.refresh") }}
              </b-button>
              <b-button
                variant="outline-primary"
                class="float-right"
                :disabled="process || loadImage"
                type="submit"
              >
                {{ $t('send') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  required,
  minLength,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import firebase from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
import 'firebase/storage'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      animation: null,
      loadImage: false,
      progress: 0,
      support: {
        name: `${this.$store.getters.currentUser.name} ${this.$store.getters.currentUser.surname}`,
        email: this.$store.getters.currentUser.email,
        title: null,
        message: null,
        file: null,
      },
      fileName: null,
    }
  },
  validations: {
    support: {
      name: {
        required,
      },
      email: {
        required,
      },
      title: {
        required,
      },
      message: {
        required,
        minLength: minLength(20),
      },
      file: {
        checkSize() {
          if (this.support.file) {
            const filesize = this.support.file.size
            const file = Math.round(filesize / 1024 / 1024)
            if (file <= 5) {
              return true
            }
            return false
          }
          return true
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'newSupportSuccess',
      error: 'newSupportError',
      process: 'newSupportProcess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('support.notifTitle'),
            text: this.$t('support.success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('support.title'),
            text: this.$t('support.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    progress(val) {
      if (val === 100) {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'CheckCircleIcon',
            text: 'Fichier uploadé avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase()
    },
    async uploadFile(event) {
      const document = Object.values(event.target.files)[0]
      this.loadImage = true
      const self = this
      const storageRef = firebase.storage().ref(`SUPPORT/${uuidv4()}`)
      // Upload file to Firebase Storage
      const uploadTask = storageRef.put(document)
      uploadTask.on('state_changed', snapshot => {
        self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (self.progress < 100) {
          self.loadImage = true
        }
      }, () => {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors du téléchargement du fichier.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.fileName = url
          this.loadImage = false
        })
      })
    },
    resetForm() {
      this.support = {
        title: null,
        message: null,
        file: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    ...mapActions(['newSupport']),
    tryToSave() {
      this.animation = null
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        return
      }
      this.newSupport({
        name: this.support.name,
        email: this.support.email,
        title: this.support.title,
        message: this.support.message,
        file_name: this.fileName,
      })
    },
  },
}
</script>

<style>

.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
